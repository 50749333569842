import { emulateTransitionEnd, getTransitionDurationFromElement, reflow, TRANSITION_END } from 'Neo/js/util'
import EventHandler from 'bootstrap/js/src/dom/event-handler'

const secondLevelNavigation = {
    init: Drupal => {
        const { isDesktopNav } = Drupal.mosaiq

        const primaryNavSubMenus = document.querySelectorAll('.primary-nav__menu-item--has-children')

        /**
         * Shows and hides the specified menu item's second level submenu.
         *
         * @param {element} menuItem - the <li> element that is the container for the menu and submenus.
         * @param {boolean} [toState] - Optional state where we want the submenu to end up.
         */
        function toggleSubNav(menuItem, toState, closeOthers = false) {
            const button = menuItem.querySelector('.primary-nav__sub-toggle')
            const link = menuItem.querySelector('.primary-nav__menu-link--sub-toggle')

            // eslint-disable-next-line no-negated-condition
            toState = toState !== undefined ? toState : button.getAttribute('aria-expanded') !== 'true'
            const state = button.getAttribute('aria-expanded') === 'true'

            const container = menuItem.querySelector('.primary-nav__sub-container')

            if (state !== toState) {
                if (toState) {
                    if (closeOthers) {
                        const siblings = menuItem.parentNode.querySelectorAll(':scope > .primary-nav__menu-item--has-children')
                        for (const el of siblings) {
                            // eslint-disable-next-line max-depth
                            if (el !== menuItem) {
                                toggleSubNav(el, false)
                            }
                        }
                    }

                    const dimension = 'height'

                    container.classList.remove('menu-collapse')
                    container.classList.add('menu-collapsing')
                    container.style[dimension] = 0
                    button.setAttribute('aria-expanded', 'true')
                    link.setAttribute('aria-expanded', 'true')

                    const complete = () => {
                        container.classList.remove('menu-collapsing')
                        container.classList.add('menu-collapse', 'is-active')

                        container.style[dimension] = ''

                        if (isDesktopNav()) {
                            Drupal.mosaiq.mqBackdrop.show('subnav') // eslint-disable-line unicorn/consistent-destructuring
                        }
                    }

                    const capitalizedDimension = dimension[0].toUpperCase() + dimension.slice(1)
                    const scrollSize = `scroll${capitalizedDimension}`
                    const transitionDuration = getTransitionDurationFromElement(container)

                    EventHandler.one(container, TRANSITION_END, complete)

                    emulateTransitionEnd(container, transitionDuration)
                    container.style[dimension] = `${container[scrollSize]}px`
                } else {
                    const childMenuItem = menuItem.querySelector('.primary-nav__menu-item--has-children')

                    if (childMenuItem) {
                        toggleSubNav(childMenuItem, false)
                    }

                    const dimension = 'height'

                    container.style[dimension] = `${container.getBoundingClientRect()[dimension]}px`

                    reflow(container)

                    container.classList.add('menu-collapsing')
                    container.classList.remove('menu-collapse', 'is-active')

                    button.setAttribute('aria-expanded', 'false')
                    link.setAttribute('aria-expanded', 'false')

                    // this.setTransitioning(true)

                    const complete = () => {
                        // this.setTransitioning(false)
                        container.classList.remove('menu-collapsing')
                        container.classList.add('menu-collapse')
                        // EventHandler.trigger(this._element, EVENT_HIDDEN)
                        Drupal.mosaiq.mqBackdrop.hide('subnav') // eslint-disable-line unicorn/consistent-destructuring
                    }

                    container.style[dimension] = ''
                    const transitionDuration = getTransitionDurationFromElement(container)

                    EventHandler.one(container, TRANSITION_END, complete)
                    emulateTransitionEnd(container, transitionDuration)
                }
            }
        }

        Drupal.mosaiq.toggleSubNav = toggleSubNav

        // Add hover and click event listeners onto each sub navigation parent and its button.
        for (const el of primaryNavSubMenus) {
            const button = el.querySelector('.primary-nav__sub-toggle')
            const link = el.querySelector('.primary-nav__menu-link--sub-toggle')

            button.removeAttribute('aria-hidden')
            button.removeAttribute('tabindex')

            link.removeAttribute('aria-hidden')
            link.removeAttribute('tabindex')

            button.addEventListener('click', e => {
                e.preventDefault()
                const menuItem = e.currentTarget.parentNode
                toggleSubNav(menuItem, undefined, true)
            })

            link.addEventListener('click', e => {
                if (isDesktopNav()) {
                    e.preventDefault()
                    const menuItem = e.currentTarget.parentNode
                    toggleSubNav(menuItem, undefined, true)
                }
            })
        }

        /**
         * Close all second level sub navigation menus.
         */
        function closeAllSubNav() {
            for (const el of primaryNavSubMenus) {
                toggleSubNav(el, false)
            }
        }

        Drupal.mosaiq.closeAllSubNav = closeAllSubNav

        /**
         * Checks if any sub navigation items are currently active.
         * @return {boolean} If sub nav is currently open.
         */
        function areAnySubNavsOpen() {
            let subNavsAreOpen = false

            for (const el of primaryNavSubMenus) {
                const toggles = el.querySelector('.primary-nav__sub-toggle, .primary-nav__menu-link--sub-toggle')
                const state = toggles.getAttribute('aria-expanded') === 'true'

                if (state) {
                    subNavsAreOpen = true
                }
            }

            return subNavsAreOpen
        }

        Drupal.mosaiq.areAnySubNavsOpen = areAnySubNavsOpen

        // Ensure that desktop submenus close when ESC key is pressed.
        document.addEventListener('keyup', e => {
            if (e.key === 'Escape' && isDesktopNav()) {
                closeAllSubNav()
            }
        })
    }
}

export default secondLevelNavigation

import { emulateTransitionEnd, getTransitionDurationFromElement, TRANSITION_END } from 'Neo/js/util'
import EventHandler from 'bootstrap/js/src/dom/event-handler'

/**
 * Checks if navWrapper contains "is-active" class.
 * @param {object} navWrapper
 *   Header navigation.
 * @return {boolean}
 *   True if navWrapper contains "is-active" class, false if not.
 */
const isNavOpen = navWrapper => navWrapper.classList.contains('is-active')

/**
 * Opens or closes the header navigation.
 * @param {object} props
 *   Navigation props.
 * @param {boolean} state
 *   State which to transition the header navigation menu into.
 */
const toggleNav = (props, state) => {
    const value = Boolean(state)
    props.navButton.setAttribute('aria-expanded', value)

    if (value) {
        props.navWrapper.classList.add('is-active')

        const dimension = 'height'

        props.navWrapper.classList.remove('menu-collapse')
        props.navWrapper.classList.add('menu-collapsing')
        props.navWrapper.style[dimension] = 0

        if (!props.mosaiq.isDesktopNav()) {
            props.mosaiq.mqBackdrop.show('mainnav')
        }

        const complete = () => {
            props.navWrapper.classList.remove('menu-collapsing')
            props.navWrapper.classList.add('menu-collapse', 'is-active')

            props.navWrapper.style[dimension] = ''
        }

        const capitalizedDimension = dimension[0].toUpperCase() + dimension.slice(1)
        const scrollSize = `scroll${capitalizedDimension}`
        const transitionDuration = getTransitionDurationFromElement(props.navWrapper)

        EventHandler.one(props.navWrapper, TRANSITION_END, complete)

        emulateTransitionEnd(props.navWrapper, transitionDuration)
        props.navWrapper.style[dimension] = `${props.navWrapper[scrollSize]}px`
    } else {
        if (props.mosaiq.areAnySubNavsOpen()) {
            props.mosaiq.closeAllSubNav()
        }

        props.navWrapper.classList.remove('is-active')
        props.mosaiq.mqBackdrop.hide('mainnav')
    }
}

/**
 * Init function for header navigation.
 * @param {object} props
 *   Navigation props.
 */
const init = props => {
    props.navButton.setAttribute('aria-controls', props.navWrapperId)
    props.navButton.setAttribute('aria-expanded', 'false')

    if (props.mosaiq.isDesktopNav()) {
        toggleNav(props, true)
    }

    props.navButton.addEventListener('click', () => {
        toggleNav(props, !isNavOpen(props.navWrapper))
    })

    // Closes any open sub navigation first, then close header navigation.
    document.addEventListener('keyup', e => {
        if (e.key === 'Escape' && !props.mosaiq.isDesktopNav() && isNavOpen(props.navWrapper)) {
            toggleNav(props, false)
        }
    })

    // Focus trap.
    props.navWrapper.addEventListener('keydown', e => {
        if (e.key === 'Tab') {
            if (e.shiftKey) {
                if (
                    document.activeElement === props.firstFocusableEl
                    && !props.mosaiq.isDesktopNav()
                ) {
                    props.navButton.focus()
                    e.preventDefault()
                }
            } else if (
                document.activeElement === props.lastFocusableEl
                && !props.mosaiq.isDesktopNav()
            ) {
                props.navButton.focus()
                e.preventDefault()
            }
        }
    })

    // Remove overlays when browser is resized and desktop nav appears.
    window.addEventListener('resize', () => {
        if (props.mosaiq.isDesktopNav()) {
            props.navWrapper.classList.add('is-active')
            props.mosaiq.closeAllSubNav()
        } else {
            toggleNav(props, false)
        }
    })

    // Remove overlays when browser is resized and desktop nav appears.
    window.addEventListener('scroll', () => {
        if (props.mosaiq.isDesktopNav() && props.mosaiq.areAnySubNavsOpen()) {
            props.mosaiq.closeAllSubNav()
        }
    })
}

const mainNavigation = {
    attach: Drupal => {
        /**
         * Initialize the navigation JS.
         */
        Drupal.behaviors.baseNavigation = {
            attach(context, settings) {
                const navWrapperId = 'header-nav'
                const navWrapper = context.querySelector(`#${navWrapperId}:not(.${navWrapperId}-processed)`)

                if (navWrapper) {
                    navWrapper.classList.add(`${navWrapperId}-processed`)
                    const { mosaiq } = Drupal
                    const navButton = context.querySelector('.mobile-nav-button')
                    const focusableNavElements = navWrapper.querySelectorAll(
                        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
                    )
                    const firstFocusableEl = focusableNavElements[0]
                    const lastFocusableEl
                        = focusableNavElements[focusableNavElements.length - 1]

                    init({
                        settings,
                        mosaiq,
                        navWrapperId,
                        navWrapper,
                        navButton,
                        firstFocusableEl,
                        lastFocusableEl
                    })
                }
            }
        }
    }
}

export default mainNavigation

import { TRANSITION_END } from './util'

const updateToolbarHeight = () => {
    // From ToolbarVisualView.js
    const header = document.querySelector('#site-header')
    let toolbarTabOuterHeight = 0
    const toolbarItems = document.querySelector('#toolbar-bar').querySelectorAll('.toolbar-tab')
    for (const item of toolbarItems) {
        toolbarTabOuterHeight = Math.max(toolbarTabOuterHeight, item.offsetHeight)
    }

    let toolbarTrayHorizontalOuterHeight = 0
    const toolbarTrayHorizontal = document.querySelector('.is-active.toolbar-tray-horizontal')
    if (toolbarTrayHorizontal) {
        toolbarTrayHorizontalOuterHeight = toolbarTrayHorizontal.offsetHeight || 0
    }

    const toolbarHeight = toolbarTabOuterHeight + toolbarTrayHorizontalOuterHeight
    header.style.top = toolbarHeight + 'px'
    document.body.style.paddingTop = (Number.parseInt(getComputedStyle(header).height, 10) + toolbarHeight) + 'px'
}

const stickyHeader = {
    init: () => {
        const stickyHeaderClass = 'sticky-header'

        if (!document.body.classList.contains(stickyHeaderClass)) {
            return
        }

        const stickyHeaderScrollingClass = 'sticky-header-scrolling'
        const stickyHeaderChangingClass = 'sticky-header-changing'
        const stickyHeaderHeight = Number.parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sticky-header-height') || 0, 10)

        if (window.pageYOffset > stickyHeaderHeight && !document.body.classList.contains(stickyHeaderScrollingClass)) {
            document.body.classList.add(stickyHeaderScrollingClass)
            document.body.classList.add(stickyHeaderChangingClass)
        }

        window.addEventListener('scroll', () => {
            if (window.pageYOffset > stickyHeaderHeight) {
                if (!document.body.classList.contains(stickyHeaderScrollingClass)) {
                    document.body.classList.add(stickyHeaderScrollingClass)
                    document.body.classList.add(stickyHeaderChangingClass)
                }
            } else if (document.body.classList.contains(stickyHeaderScrollingClass)) {
                document.body.classList.remove(stickyHeaderScrollingClass)
                document.body.classList.add(stickyHeaderChangingClass)
            }
        })

        const header = document.querySelector('#site-header')
        header.addEventListener(TRANSITION_END, e => {
            if (e.propertyName === 'height') {
                document.body.classList.remove(stickyHeaderChangingClass)
            }
        })

        /**
         * When in Drupal mode we need to calculate position:fixed elements different because of the Admin Toolbar
         * which sets padding top to the body tag
         */
        const toolbar = document.getElementById('toolbar-administration')

        const observer = new MutationObserver((mutationsList, _observer) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
                    updateToolbarHeight()
                }
            }
        })

        if (toolbar && header) {
            window.addEventListener('load', () => {
                updateToolbarHeight()
            })

            observer.observe(document.body, {
                attributes: true,
                childList: false,
                subtree: false
            })
        }
    }
}

export default stickyHeader

import Masonry from 'masonry-layout'

/**
 * Initialize masonry
 */
export default function init() {
    if (document.querySelector('.grid') !== null) {
        const elements = document.querySelectorAll('.grid')

        for (let i = 0; i < elements.length; i++) {
            const msnry = new Masonry(elements[i], {
                itemSelector: '.grid > *:not(.grid-sizer)',
                // itemSelector: '.grid > .grid-item', // before baseupdate 1.1
                columnWidth: '.grid-sizer',
                percentPosition: true
            })

            elements[i].addEventListener('insertTeaserEntities', _elem => {
                msnry.reloadItems()
                msnry.layout()
            }, false)

            elements[i].addEventListener('ajax_update', _elem => {
                msnry.masonry('layout')
            }, false)
        }

        /**
         * Update masonry inside tabs
         */
        const tabsTriggerList = document.querySelectorAll('[data-toggle="tab"]')
        for (const tabTriggerEl of tabsTriggerList) {
            tabTriggerEl.addEventListener('shown.bs.tab', e => {
                const element = e.target

                if (element.tagName === 'A' || element.tagName === 'BUTTON') {
                    const target = element.getAttribute('data-target') ? element.getAttribute('data-target') : element.getAttribute('href')
                    if (target) {
                        const tab = document.querySelector(target)

                        const grid = tab.querySelector('.grid')
                        if (grid) {
                            const event = new Event('reloadMasonry')
                            grid.dispatchEvent(event)
                            // return
                        }

                        // DISABLE CODE BELOW AND USE observeParents OPTION FROM SWIPER:
                        // https://swiperjs.com/swiper-api#methods-and-properties

                        // const sliders = tab.querySelectorAll('.swiper-container')
                        // for (const slider of sliders) {
                        //     const mySwiper = slider.swiper
                        //     mySwiper.update()

                        //     setTimeout(() => {
                        //         mySwiper.update()
                        //     }, 500)
                        // }
                    }
                }
            })
        }
    }
}

import SwiperCore, { Autoplay, A11y, Controller, EffectCoverflow, Keyboard, Navigation, Pagination, Scrollbar, Thumbs } from 'swiper'
import { mergeDeep } from 'Neo/js/util/object'

// configure Swiper to use modules
SwiperCore.use([Autoplay, A11y, Controller, EffectCoverflow, Keyboard, Navigation, Pagination, Scrollbar, Thumbs])

const defaultOptions = {
    slidesPerView: 1,
    loop: true,
    watchOverflow: true,
    observer: true,
    observeParents: true
}

const coverflowNavigationPosition = swiper => {
    const params = swiper.params.navigation
    if (!(params.nextEl || params.prevEl)) {
        return
    }

    if (typeof swiper.navigation.$prevEl === 'undefined' || typeof swiper.navigation.$nextEl === 'undefined') {
        swiper.navigation.init()
    }

    const { $nextEl, $prevEl } = swiper.navigation

    if (($prevEl && $prevEl.length > 0) && ($nextEl && $nextEl.length > 0)) {
        // remove style
        $nextEl[0].style.removeProperty('right')
        $prevEl[0].style.removeProperty('left')

        if (swiper.params.useNavigationPosCalc) {
            // get max slide width
            const maxWidth = Math.max(...swiper.slidesSizesGrid)
            // calc position for next and prev button
            const pos = ((swiper.width - maxWidth) / 2) - (maxWidth / 6)

            // position the next and prev button
            $nextEl[0].style.right = (pos - $nextEl[0].offsetWidth) + 'px'
            $prevEl[0].style.left = (pos - $prevEl[0].offsetWidth) + 'px'
        }
    }
}

export class SwiperHelper {
    constructor(element, options = {}) {
        this.element = element
        this.options = options

        if (typeof this.options.type === 'undefined') {
            throw new TypeError('You have to specify a slider type')
        }

        this.swiper = this.createSwiper()
    }

    createSwiper() {
        let options = this.getOptions(this.options.type)

        if (this.options.config && typeof this.options.config === 'object') {
            options = mergeDeep(options, this.options.config)
        }

        if (this.options.contentLength <= 1) {
            options.loop = false
        }

        return new SwiperCore(this.element, options)
    }

    getOptions(type) {
        const options = { ...defaultOptions }

        switch (type) {
            case 'default':
                options.slidesPerView = 1
                options.spaceBetween = 30
                options.loop = false
                options.breakpoints = undefined
                break
            case 'grid':
                options.spaceBetween = 16 * 1.5
                options.loop = false
                // options.observer = true
                // options.observeSlideChildren = true
                break
            case 'coverflow':
                options.effect = 'coverflow'
                options.centeredSlides = true
                options.slidesPerView = 1
                options.grabCursor = true
                options.parallax = true
                options.useNavigationPosCalc = false
                options.coverflowEffect = {
                    rotate: 0,
                    stretch: -80,
                    depth: 150,
                    modifier: 1,
                    slideShadows: false
                }
                options.breakpoints = {
                    768: {
                        slidesPerView: 2,
                        useNavigationPosCalc: true
                    }
                }
                options.on = {
                    init: swiper => coverflowNavigationPosition(swiper),
                    resize: swiper => coverflowNavigationPosition(swiper)
                }

                break
            case 'thumb':
                if (this.options.contentLength > 1) {
                    options.slidesPerView = 1
                    options.thumbs = {
                        swiper: {
                            spaceBetween: 7.5,
                            slidesPerView: 2,
                            observer: true,
                            observeParents: true,
                            loopedSlides: 2,
                            watchSlidesVisibility: true,
                            watchSlidesProgress: true,
                            breakpoints: {
                                768: {
                                    spaceBetween: 15,
                                    slidesPerView: 4,
                                    loopedSlides: 4
                                }
                            }
                        }
                    }
                }

                break
            default:
                break
        }

        return options
    }
}

/**
 * Initialize Sliders
 */
export default function init(selector = '.slider-wrapper') {
    const sliders = document.querySelectorAll(selector)

    for (const slider of sliders) {
        const swipers = slider.querySelectorAll('[data-toggle="swiper"]')

        for (const swiper of swipers) {
            const options = {
                type: swiper.dataset.swiperType,
                contentLength: Number.parseInt(swiper.dataset.swiperContentLength, 10),
                config: {
                    breakpoints: {}
                }
            }

            if (swiper.dataset.swiperType === 'grid') {
                const firstNode = swiper.querySelector('.node')

                options.config.breakpoints = {
                    576: {
                        slidesPerView: 2
                    },
                    992: {
                        slidesPerView: 3
                    }
                }

                if (firstNode !== null && (firstNode.classList.contains('node--type-location') || firstNode.classList.contains('node--type-job'))) {
                    options.config.breakpoints = {
                        576: {
                            slidesPerView: 2
                        }
                    }
                } else if (firstNode !== null && firstNode.classList.contains('node--type-statement')) {
                    options.config.loop = false
                    options.config.breakpoints = {}
                }
            }

            if (typeof swiper.dataset.swiperConfig !== 'undefined') {
                options.config = { ...JSON.parse(swiper.dataset.swiperConfig), ...options.config }
            }

            // eslint-disable-next-line no-unused-vars
            const mySwiper = new SwiperHelper(swiper, options)
        }
    }
}

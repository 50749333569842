import { loadSpritemap } from 'Neo/js/util'
import { isIE } from 'Neo/js/util/browsers'
import { Modal } from 'bootstrap' // eslint-disable-line no-unused-vars

import mosaiq from 'Client/js/override-base/mosaiq'
// import mosaiq from 'Neo/js/mosaiq'
import bootstrapGridHelper from 'Neo/js/bootstrap-grid-helper'
import breakpoints from 'Neo/js/breakpoints'
import stickyHeader from 'Neo/js/sticky-header'
import mainNavigation from 'Client/js/override-base/navigation'
// import mainNavigation from 'Neo/js/navigation'
import secondLevelNavigation from 'Client/js/override-base/second-level-navigation'
// import secondLevelNavigation from 'Neo/js/second-level-navigation'
import search from 'Client/js/override-base/search'
// import search from 'Neo/js/search'
import statusMessages from 'Neo/js/messages'
import client from 'Client/js/client'

// Spritemap
// ---------------
loadSpritemap('/build/spritemap.svg')

// Additional
// ---------------
const toolbar = document.querySelector('.toolbar')
if (toolbar) {
    import(
        /* webpackChunkName: "admin" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        'Client/scss/admin.scss')
}

import(
    /* webpackChunkName: "print" */
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    /* webpackPreload: true */
    'Client/scss/print.scss')

if (isIE()) {
    import(
        /* webpackChunkName: "bootstrap-ie11" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        'bootstrap-ie11/scss/bootstrap-ie11.scss')
}

// Neo
// ---------------
mosaiq.init(window.Drupal)
breakpoints.init(window.Drupal)
bootstrapGridHelper(window.Drupal, window.drupalSettings, window.once)
mainNavigation.attach(window.Drupal)
secondLevelNavigation.init(window.Drupal)
search.init(window.Drupal)
statusMessages.attach(window.Drupal)
stickyHeader.init()

// Client
// ---------------
client.init(window.Drupal)

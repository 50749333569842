/**
 * Popover - general
 */

export default function init() {
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
    for (const popoverTriggerEl of popoverTriggerList) {
        popoverTriggerEl.addEventListener('hidden.bs.popover', () => {
            this.classList.remove('is-active')
        })

        popoverTriggerEl.addEventListener('show.bs.popover', () => {
            this.classList.add('is-active')
        })
    }
}

/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 * @param {...object} objects - Objects to merge
 * @returns {object} New object with merged key/values
 */
const mergeDeep = (...objects) => {
    const isObject = obj => obj && typeof obj === 'object'

    // eslint-disable-next-line unicorn/no-array-reduce, unicorn/prefer-object-from-entries
    return objects.reduce((prev, obj) => {
        for (const key of Object.keys(obj)) {
            const pVal = prev[key]
            const oVal = obj[key]

            if (Array.isArray(pVal) && Array.isArray(oVal)) {
                prev[key] = pVal.concat(...oVal)
            } else if (isObject(pVal) && isObject(oVal)) {
                prev[key] = mergeDeep(pVal, oVal)
            } else {
                prev[key] = oVal
            }
        }

        return prev
    }, {})
}

/**
 * Get the next key of a given key from a object
 *
 * @param object
 * @param key
 * @returns {string|undefined}
 */
const next = (object, key) => {
    if (typeof object !== 'object') {
        return
    }

    const keys = Object.keys(object)
    const index = keys.indexOf(key)
    const nextIndex = index + 1

    if (nextIndex >= keys.length) {
        return
    }

    return keys[nextIndex]
}

/**
 * Get the prev key of a given key from a object
 *
 * @param object
 * @param key
 * @returns {string|undefined}
 */
const prev = (object, key) => {
    if (typeof object !== 'object') {
        return
    }

    const keys = Object.keys(object)
    const index = keys.indexOf(key)
    const prevIndex = index - 1

    if (prevIndex < 0) {
        return
    }

    return keys[prevIndex]
}

export {
    mergeDeep,
    next,
    prev
}

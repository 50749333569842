import { jarallax } from 'jarallax'

/**
 * Initialize Parallax
 */
export default function init() {
    jarallax(document.querySelectorAll('.paragraph--parallax:not(.lazyload):not(.lazyloading)'), {
        speed: 0.2
    })

    const lazyloadingElements = document.querySelectorAll('.lazyload, .lazyloading')
    if (lazyloadingElements !== null) {
        document.addEventListener('lazyloaded', e => {
            if (e.target.hasAttribute('data-bg') && e.target.classList.contains('paragraph--parallax')) {
                jarallax(e.target, {
                    speed: 0.2
                })
            }
        })
    }
}

const statusMessages = {
    closeMessage: (message, Drupal) => {
        const messageContainer = message.querySelector('.messages__container')
        const closeBtnWrapper = document.createElement('div')
        closeBtnWrapper.setAttribute('class', 'messages__button')
        const closeBtn = document.createElement('button')
        closeBtn.setAttribute('type', 'button')
        closeBtn.setAttribute('class', 'messages__close')
        const closeBtnText = document.createElement('span')
        closeBtnText.setAttribute('class', 'visually-hidden')
        closeBtnText.textContent = Drupal.t('Close message')
        messageContainer.append(closeBtnWrapper)
        closeBtnWrapper.append(closeBtn)
        closeBtn.append(closeBtnText)
        message.classList.add('messages-processed')

        closeBtn.addEventListener('click', () => {
            message.classList.add('hidden')
        })
    },

    attach: Drupal => {
        Drupal.behaviors.messages = {
            attach: context => {
                const messages = context.querySelectorAll('.messages:not(.messages-processed)')

                for (const message of messages) {
                    statusMessages.closeMessage(message, Drupal)
                }
            }
        }
    }
}

export default statusMessages

import { next, prev } from './util/object'

const breakpointsObject = (() => ({
    xs: Number.parseInt(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-xs') || 0, 10),
    sm: Number.parseInt(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-sm') || 576, 10),
    md: Number.parseInt(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-md') || 768, 10),
    lg: Number.parseInt(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-lg') || 992, 10),
    xl: Number.parseInt(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-xl') || 1200, 10),
    xxl: Number.parseInt(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-xxl') || 1400, 10)
}))()

const breakpointMin = name => {
    const min = breakpointsObject[name]

    if (min !== 0) {
        return min
    }
}

const breakpointMax = name => {
    const n = next(breakpointsObject, name)

    if (n) {
        return breakpointMin(n, breakpointsObject) - 0.02
    }
}

const breakpointNav = () => getComputedStyle(document.documentElement).getPropertyValue('--primary-nav-breakpoint').trim() || 'xl'

const breakpointNavPrev = () => prev(breakpointsObject, breakpointNav())

const breakpoints = {
    init: Drupal => {
        Drupal.breakpoints = {
            isBreakpointUp(name) {
                const min = breakpointMin(name)

                if (min) {
                    return window.matchMedia(`only screen and (min-width: ${min}px)`).matches
                }

                return false
            },

            isBreakpointDown(name) {
                const max = breakpointMax(name)

                if (max) {
                    return window.matchMedia(`only screen and (max-width: ${max}px)`).matches
                }

                return false
            },

            isBreakpointBetween(lower, upper) {
                const min = breakpointMin(lower)
                const max = breakpointMax(upper)

                if (min !== undefined && max !== undefined) {
                    return window.matchMedia(`only screen and (min-width: ${min}px) and (max-width: ${max}px)`).matches
                }

                return max === undefined ? this.isBreakpointUp(lower) : this.isBreakpointDown(upper)
            },

            isBreakpointOnly(name) {
                const min = breakpointMin(name)
                const max = breakpointMax(name)

                if (min !== undefined && max !== undefined) {
                    return window.matchMedia(`only screen and (min-width: ${min}px) and (max-width: ${max}px)`).matches
                }

                return max === undefined ? this.isBreakpointUp(name) : this.isBreakpointDown(name)
            },

            isMobileNav() {
                const breakpoint = breakpointNavPrev()
                return this.isBreakpointDown(breakpoint)
            },

            isDesktopNav() {
                const breakpoint = breakpointNav()
                return this.isBreakpointUp(breakpoint)
            }
        }
    }
}

export default breakpoints

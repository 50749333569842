import { Tab, Collapse } from 'bootstrap'
import 'fslightbox'
import lazyLoading from './components/lazy-loading'
import masonry from './components/masonry'
import parallax from './components/parallax'
import popover from './components/popover'
import slider from './components/swiper-helper'
// import slimSelect from './components/slim-select'
// TODO change this import after base-system is updated and hat this pull-request https://bitbucket.org/mosaiq-gmbh/drupal.theme-neo/pull-requests/4/feature-styleable-select-fields
import Select from './components/base-select.js'
// import Select from '../../../mosaiq/neo/assets/js/select'
import 'simplebar'
import { getjQuery } from 'bootstrap/js/src/util'
import '@mosaiq/seo-check'

const client = {
    init: () => {
        lazyLoading()
        masonry()
        parallax()
        popover()
        slider()
        // slimSelect()

        if (document.querySelector('.contactperson')) {
            import(/* webpackChunkName: "contactperson" */ './components/contactperson')
        }

        /**
         * Tabs functionality for RWD
         */
        if (document.querySelector('.nav-tabs-mobile')) {
            // switch between tab or select mode; if too many tabs or tabs to big for the viewport
            const showTabOrSelect = () => {
                const allTabs = document.querySelectorAll('.nav-tabs')
                for (const tab of allTabs) {
                    const tabW = tab.offsetWidth
                    let totalW = 0

                    for (let i = 0; i < tab.children.length; i++) {
                        const childStyle = window.getComputedStyle(tab.children[i])
                        const ml = Number.parseFloat(childStyle.marginLeft) || 0
                        const mr = Number.parseFloat(childStyle.marginRight) || 0

                        totalW += tab.children[i].offsetWidth + ml + mr
                    }

                    const showMobile = totalW > tabW
                    tab.parentNode.classList.toggle('show--mobile', showMobile)
                }
            }

            // initial call
            showTabOrSelect()

            // add global function to can call resize.end event
            // @todo adding to base-system?
            window.resizeTimer = false
            window.resizeEndEvent = new Event('resize.end')
            window.addEventListener('resize', () => {
                clearTimeout(window.resizeTimer)
                window.resizeTimer = setTimeout(() => {
                    window.resizeTimer = false
                    window.dispatchEvent(window.resizeEndEvent)
                }, 200)
            })

            // call it on window resize is end
            window.addEventListener('resize.end', () => {
                showTabOrSelect()
            })

            // trigger bootstrap tab-func if select field changed (select mode)
            const mobileTabs = document.querySelectorAll('.nav-tabs-mobile')
            for (const tab of mobileTabs) {
                tab.addEventListener('change', event => {
                    const tabbed = document.querySelector(event.target.value)
                    new Tab(tabbed).show()
                })
            }

            // update select field if tab changed (tab mode)
            const tabEls = document.querySelectorAll('[data-bs-toggle="tab"]')
            for (const el of tabEls) {
                el.addEventListener('show.bs.tab', event => {
                    document.querySelector('[value="#' + event.target.id + '"]').selected = 'selected'
                })
            }
        }

        /**
         * adding Backdrop to all collapse with data-collapse-backdrop
         * and close all others before (navigation, search-header)
         */
        const allBackdrops = document.querySelectorAll('[data-collapse-backdrop]')
        for (const el of allBackdrops) {
            el.addEventListener('show.bs.collapse', () => {
                window.Drupal.mosaiq.mqBackdrop.show('collapse')
            })

            el.addEventListener('hidden.bs.collapse', () => {
                window.Drupal.mosaiq.mqBackdrop.hide('collapse')
            })

            document.addEventListener('keyup', e => {
                if (e.key === 'Escape' && el.classList.contains('show')) {
                    const collapse = Collapse.getInstance(el)
                    if (collapse !== null) {
                        collapse.hide()
                    }
                }
            })
        }

        /**
         * initialize tables
         *   to add css class .table
         *   to add responsive wrapper
         */
        const allTable = document.querySelectorAll('table:not(.table)')
        for (const table of allTable) {
            // important to add wrapper-div before table move
            const div = document.createElement('div')
            div.classList.add('table-responsive')
            div.dataset.simplebar = ''
            table.before(div)

            // set table and move to wrapper-div
            table.classList.add('table')
            table.previousElementSibling.prepend(table)

            // adding special table class if table has 2 columns
            if (!table.classList.contains('table--fixed') && table.rows !== undefined && table.rows[0].cells.length === 2) {
                table.classList.add('table--2col')
            }
        }

        /**
         * select field in form (not multiple)
         * adding or remove css class if has a option with value selected
         */
        const allFormSelect = document.querySelectorAll('form select:not(multiple)')
        for (const select of allFormSelect) {
            select.addEventListener('change', function () {
                const classname = 'form-select--selected'
                const val = this.selectedOptions[0].getAttribute('value')

                if (val === null || val === '') {
                    this.classList.remove(classname)
                } else {
                    this.classList.add(classname)
                }
            })
        }

        /**
         * Adding Bootstrap custom validation style
         * disabling form submissions if there are invalid fields
         */
        const allForm = document.querySelectorAll('.needs-validation')
        for (const form of allForm) {
            form.addEventListener('submit', e => {
                if (!form.checkValidity()) {
                    e.preventDefault()
                    e.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)
        }

        const allSelects = document.querySelectorAll('select:not([multiple]):not([data-drupal-autosubmit])')
        for (let i = 0; i < allSelects.length; i++) {
            const el = allSelects[i]
            new Select(el) // eslint-disable-line no-new

            // move label to select
            const label = document.querySelector('[for="' + el.getAttribute('id') + '"]')
            el.after(label || '')
        }

        const jQuery = getjQuery()
        if (jQuery) {
            jQuery(document).on('click', '[data-offcanvas]', e => {
                const id = e.target.dataset.offcanvas
                const parent = jQuery(id)

                if (parent.next('.modal-backdrop').length === 0) {
                    parent.after('<div class="modal-backdrop fade show"></div>')
                }

                const modal = parent.next('.modal-backdrop')

                if (parent.hasClass('show')) {
                    parent.removeClass('show').attr('aria-modal', false)
                    modal.hide()
                } else {
                    parent.addClass('show').css('visibility', 'visible').attr('aria-modal', true)
                    modal.show()
                }

                modal.on('click', () => {
                    parent.removeClass('show').attr('aria-modal', false)
                    modal.hide()
                })
            })
        }
    }
}

export default client

import Swiper, { Navigation, Pagination } from 'swiper'

Swiper.use([Navigation, Pagination])
const infoTileAll = document.querySelectorAll('.info-tile')
let sliderTest

function screenTest(e) {
    if (e.matches) {
        /* Viewport Mobile */
        const allTileSlider = document.querySelectorAll('.info-tile .swiper-container')
        for (const tileSlider of allTileSlider) {
            const contentLength = tileSlider.dataset.swiperContentLength

            sliderTest = new Swiper(tileSlider, {
                scrollbar: {
                    el: '.swiper-scrollbar',
                    draggable: true,
                    contentLength
                }
            })
        }
    } else if (sliderTest) {
        sliderTest.destroy()
    }
}

// --=== M-A-I-N ===--

if (infoTileAll) {
    for (const infoTile of infoTileAll) {
        const infoTileList = infoTile.querySelector('.info-tile__list')

        // Click on tile
        infoTileList.addEventListener('click', ev => {
            const tile = ev.target.matches('.info-tile__item') ? ev.target : ev.target.closest('.info-tile__item')
            if (tile) {
                const boxContent = tile.querySelector('.info-tile--big')
                const flyer = infoTile.querySelector('.info-tile__flyer')
                const flyerContent = flyer.querySelector('.info-tile__flyer-content')

                // Remove all nodes
                while (flyerContent.firstChild) {
                    flyerContent.firstChild.remove()
                }

                // Append data
                for (const item of boxContent.children) {
                    flyerContent.append(item.cloneNode(true))
                }

                // Show flyer
                flyer.classList.add('info-tile__flyer--open')
                flyer.parentElement.classList.add('info-tile--open')
            }
        })

        // Close flyer
        const flyoutClose = infoTile.querySelector('.info-tile__flyer-close')
        flyoutClose.addEventListener('click', ev => {
            const el = ev.target.closest('.info-tile__flyer')
            el.classList.remove('info-tile__flyer--open')
            el.parentElement.classList.remove('info-tile--open')
        })
    }

    // Mobile slider
    const mql = window.matchMedia('(max-width: 767px)')
    mql.addEventListener('change', screenTest)
    screenTest(mql)
}

import MqBackdrop from 'Client/js/components/mq-backdrop'

const mosaiq = {
    init: Drupal => {
        Drupal.mosaiq = {}

        function isDesktopNav() {
            const navButtons = document.querySelector('.mobile-buttons')
            if (navButtons) {
                return (
                    getComputedStyle(navButtons).getPropertyValue('display') === 'none'
                )
            }

            return false
        }

        Drupal.mosaiq.isDesktopNav = isDesktopNav

        Drupal.mosaiq.mqBackdrop = new MqBackdrop()
    }
}

export default mosaiq

import Backdrop from 'bootstrap/js/src/util/backdrop'
import { Collapse } from 'bootstrap'

class mqBackdrop {
    constructor() {
        this.backdrop = document.querySelector('.modal-backdrop--extended')

        if (this.backdrop === null) {
            this._create()
        }
    }

    _create() {
        this.backdrop = new Backdrop({
            isVisible: true,
            isAnimated: true,
            rootElement: document.body,
            clickCallback: () => {
                this._callHide()
            }
        })
        this.backdrop._getElement().classList.add('modal-backdrop--extended')
    }

    show(str) {
        this.backdrop._getElement().dataset.openBy = str
        this.backdrop.show(() => {
            switch (str) {
                case 'mainnav':
                    this._closeSearch()
                    this._closeCollapse()
                    break
                case 'subnav':
                    this._closeSearch()
                    this._closeCollapse()
                    break
                case 'search':
                    this._closeMainnav()
                    this._closeSubnav()
                    this._closeCollapse()
                    break
                case 'collapse':
                    this._closeSearch()
                    this._closeMainnav()
                    this._closeSubnav()
                    break
                default:
                    this._callHide()
                    break
            }
        })
    }

    hide(str) {
        if (str === this.backdrop._getElement().dataset.openBy) {
            this.backdrop.hide()
        }
    }

    _callHide() {
        this.backdrop.hide()

        this._closeSearch()
        this._closeMainnav()
        this._closeSubnav()
        this._closeCollapse()
    }

    _closeSearch() {
        // close search header if visible
        if (window.Drupal.mosaiq.searchIsVisible()) {
            window.Drupal.mosaiq.toggleSearchVisibility(false)
        }
    }

    _closeMainnav() {
        // hide navigation (mobile)
        if (!window.Drupal.mosaiq.isDesktopNav()) {
            document.querySelector('.mobile-nav-button').setAttribute('aria-expanded', false)
            document.getElementById('header-nav').classList.remove('is-active')
        }
    }

    _closeSubnav() {
        // close all subnavigation
        window.Drupal.mosaiq.closeAllSubNav()
    }

    _closeCollapse() {
        const collapse = Collapse.getInstance(document.querySelector('[data-collapse-backdrop].show'))
        if (collapse !== null) {
            collapse.hide()
        }
    }
}

export default mqBackdrop

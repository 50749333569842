function setupCustomElement(select) {
    // id for bootstrap functionality
    const id = select.element.getAttribute('id') + '--custom'
    // remove selectable / focused with keyboard
    select.element.setAttribute('tabindex', '-1')
    // adding wrapper and bootstrap-dropdown
    select.custom.classList.add('dropdown', 'form-select--custom')

    // adding dropdown-button for open dropdown-menu
    select.btn.classList.add('dropdown-toggle')
    select.btn.setAttribute('type', 'button')
    select.btn.setAttribute('id', id)
    select.btn.dataset.bsToggle = 'dropdown'
    select.btn.setAttribute('aria-expanded', false)
    select.custom.append(select.btn)

    // adding dropdown-menu with dropdown-items
    select.list.classList.add('dropdown-menu')
    select.options.forEach(option => {
        const item = document.createElement('li')
        const label = document.createElement('a')
        label.classList.add('dropdown-item')
        label.classList.toggle('active', option.selected)
        label.textContent = option.label
        label.dataset.value = option.value
        label.addEventListener('click', () => {
            select.selectValue(option.value)
        })
        item.append(label)
        select.list.append(item)
    })
    select.list.setAttribute('aria-labelledby', id)
    select.custom.append(select.list)
}

function getOptions(options) {
    // set htmlcollection to array
    options = Array.from(options)

    return [...options].map(option => {
        const rObj = {
            value: option.value,
            label: option.label,
            selected: option.selected,
            element: option
        }

        return rObj
    })
}

export default class Select {
    constructor(element) {
        // create bootstrap-dropdown structure and elements
        this.element = element
        this.options = getOptions(element.options)
        this.custom = document.createElement('div')
        this.btn = document.createElement('button')
        this.list = document.createElement('ul')
        setupCustomElement(this)

        // element.style.display = 'none'

        // adding dropdown after select field
        element.after(this.custom)
        // move select field into dropdown-wrapper
        this.custom.append(element)
    }

    /**
     * get the selected option
     * @returns {*}
     */
    get selectedOption() {
        return this.options.find(option => option.selected)
    }

    /**
     * get the index of selected option
     * @returns {integer}
     */
    get selectedOptionIndex() {
        return this.element.selectedIndex
    }

    /**
     * set the value on select field and set active to dropdown-item
     * @param {string} value
     */
    selectValue(value) {
        // set value of the select field (selected option)
        this.element.value = value

        // remove all dropdown-item selected
        this.list.querySelectorAll('.dropdown-item').forEach((item, i) => {
            const isSelected = i === this.selectedOptionIndex
            item.classList.toggle('active', isSelected)
        })

        // call event to trigger change of select field
        this.element.dispatchEvent(new Event('change'))
    }
}

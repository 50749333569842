const MILLISECONDS_MULTIPLIER = 1000
const TRANSITION_END = 'transitionend'

const getSelector = element => {
    let selector = element.getAttribute('data-target')

    if (!selector || selector === '#') {
        const hrefAttr = element.getAttribute('href')

        selector = hrefAttr && hrefAttr !== '#' ? hrefAttr.trim() : undefined
    }

    return selector
}

const getSelectorFromElement = element => {
    const selector = getSelector(element)

    if (selector) {
        return document.querySelector(selector) ? selector : undefined
    }
}

const getElementFromSelector = element => {
    const selector = getSelector(element)

    return selector ? document.querySelector(selector) : undefined
}

const getTransitionDurationFromElement = element => {
    if (!element) {
        return 0
    }

    // Get transition-duration of the element
    let { transitionDuration, transitionDelay } = window.getComputedStyle(element)

    const floatTransitionDuration = Number.parseFloat(transitionDuration)
    const floatTransitionDelay = Number.parseFloat(transitionDelay)

    // Return 0 if element or transition duration is not found
    if (!floatTransitionDuration && !floatTransitionDelay) {
        return 0
    }

    // If multiple durations are defined, take the first
    transitionDuration = transitionDuration.split(',')[0]
    transitionDelay = transitionDelay.split(',')[0]

    return (Number.parseFloat(transitionDuration) + Number.parseFloat(transitionDelay)) * MILLISECONDS_MULTIPLIER
}

const triggerTransitionEnd = element => {
    element.dispatchEvent(new Event(TRANSITION_END))
}

const isElement = obj => (obj[0] || obj).nodeType

const getElement = element => {
    let el

    if (isElement(element)) {
        el = element

        // It's a jQuery object
        if (typeof element.jquery !== 'undefined') {
            el = element[0]
        }
    } else {
        el = document.querySelector(element)
    }

    return el
}

const emulateTransitionEnd = (element, duration) => {
    let called = false
    const durationPadding = 5
    const emulatedDuration = duration + durationPadding

    function listener() {
        called = true
        element.removeEventListener(TRANSITION_END, listener)
    }

    element.addEventListener(TRANSITION_END, listener)
    setTimeout(() => {
        if (!called) {
            triggerTransitionEnd(element)
        }
    }, emulatedDuration)
}

const isVisible = element => {
    if (!element) {
        return false
    }

    if (element.style && element.parentNode && element.parentNode.style) {
        const elementStyle = getComputedStyle(element)
        const parentNodeStyle = getComputedStyle(element.parentNode)

        return elementStyle.display !== 'none'
            && parentNodeStyle.display !== 'none'
            && elementStyle.visibility !== 'hidden'
    }

    return false
}

const isNumeric = n => !Number.isNaN(Number.parseFloat(n)) && Number.isFinite(n)

const reflow = element => element.offsetHeight

const onDOMContentLoaded = callback => {
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', callback)
    } else {
        callback()
    }
}

const addMultipleEventListener = (element, events, handler) => {
    for (const e of events) {
        element.addEventListener(e, handler)
    }
}

const loadSpritemap = path => {
    const req = new XMLHttpRequest()
    req.open('GET', path)
    req.responseType = 'document'
    req.overrideMimeType('text/xml')
    req.addEventListener('load', () => {
        if (req.status === 200 && req.responseXML) {
            const wrapper = document.createElement('div')
            wrapper.className = 'spritemap d-none'
            // Use appendChild instead append because it has native IE5 Support
            wrapper.appendChild(req.responseXML.documentElement)
            document.body.insertAdjacentElement('afterbegin', wrapper)
        }
    })
    req.send()
}

export {
    TRANSITION_END,
    getSelectorFromElement,
    getElementFromSelector,
    getTransitionDurationFromElement,
    triggerTransitionEnd,
    isElement,
    getElement,
    emulateTransitionEnd,
    isVisible,
    isNumeric,
    reflow,
    onDOMContentLoaded,
    addMultipleEventListener,
    loadSpritemap
}

// eslint-disable-next-line no-unused-vars
import lazySizes from 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'

/**
 * Initialize loading animation for Lazy Loading
 */
export default function init() {
    const lazyloadingElements = document.querySelectorAll('.lazyload, .lazyloading')

    if (lazyloadingElements !== null) {
        document.addEventListener('lazybeforeunveil', e => {
            const pictureTag = e.target.parentElement

            if (pictureTag.tagName === 'PICTURE') {
                pictureTag.classList.add('throbber', 'throbber--lazyloading', 'lazyloading-picture')
            }

            if (e.target.hasAttribute('data-bg')) {
                e.target.classList.add('throbber', 'throbber--lazyloading', 'lazyloading-bg')
            }
        })

        document.addEventListener('lazyloaded', e => {
            const pictureTag = e.target.parentElement

            if (pictureTag.classList.contains('lazyloading-picture')) {
                pictureTag.classList.remove('throbber', 'throbber--lazyloading', 'lazyloading-picture')
            }

            if (e.target.classList.contains('lazyloading-bg')) {
                e.target.classList.remove('throbber', 'throbber--lazyloading', 'lazyloading-bg')
            }

            if (e.target.tagName === 'IMG' && e.target.hasAttribute('data-alt')) {
                const altText = e.target.dataset.alt

                if (altText) {
                    e.target.setAttribute('alt', altText)
                    delete e.target.dataset.alt
                }
            }
        })
    }
}

import { mergeDeep } from './util/object'

const defaultSettings = {
    /**
     * The default selector, overridden by drupalSettings.
     *
     * @type {string}
     */
    selector: '.paragraph:not(.paragraph--sub)',

    /**
     * The default horizontal gutter width.
     *
     * @type {string}
     */
    gutterX: getComputedStyle(document.documentElement).getPropertyValue('--paragraphs-cols-gutter-x') || '1.5rem',

    /**
     * The default vertical gutter width.
     *
     * @type {string}
     */
    gutterY: getComputedStyle(document.documentElement).getPropertyValue('--paragraphs-cols-gutter-y') || '1.5rem'
}

class BootstrapGridHelper {
    constructor(Drupal, drupalSettings, once, settings = {}) {
        this.settings = mergeDeep(defaultSettings, settings, drupalSettings.bootstrapGridHelper || {})
        this.rows = []
        this.init(Drupal, once)
    }

    init(Drupal, once) {
        Drupal.behaviors.bootstrapGridHelper = {
            attach: context => {
                const elements = context.querySelectorAll(this.settings.selector)
                const rows = []

                if (elements.length) {
                    elements.forEach(element => {
                        const selectedRows = element.querySelectorAll('.row')
                        if (selectedRows.length) {
                            selectedRows.forEach(row => {
                                if (row.firstElementChild && row.firstElementChild.matches('[class*="col"]')) {
                                    rows.push(row)
                                }
                            })
                        }
                    })
                }

                if (rows.length) {
                    this.rows = [...this.rows, ...once('bootstrapGridHelper', rows)]

                    // ie11 and lower doesn't support ResizeObserver
                    // tested the script with @juggle/resize-observer
                    // but ie11-custom-properties doesn't work on resize either (it loads the wrong media query)
                    if ('ResizeObserver' in window) {
                        const resizeObserver = new ResizeObserver(entries => {
                            for (const entry of entries) {
                                this.setProperties(entry.target)
                            }
                        })

                        rows.forEach(row => {
                            resizeObserver.observe(row)
                        })
                    }

                    rows.forEach(row => {
                        this.setProperties(row)
                    })
                }
            }
        }
    }

    setProperties(row) {
        row.style.removeProperty('--bs-gutter-x')
        row.style.removeProperty('--bs-gutter-y')

        const children = Array.from(row.children)

        if (row.dataset.paragraphsGutterMixed) {
            delete row.dataset.paragraphsGutterMixed

            children.forEach(col => {
                col.style.removeProperty('--bs-gutter-x')
                col.style.removeProperty('--bs-gutter-y')
            })
        }

        const rowGutterX = getComputedStyle(row).getPropertyValue('--bs-gutter-x')
        const rowGutterY = getComputedStyle(row).getPropertyValue('--bs-gutter-y')

        let guttersX = []
        let guttersY = []
        children.forEach(col => {
            guttersX.push(getComputedStyle(col).getPropertyValue('--bs-gutter-x'))
            guttersY.push(getComputedStyle(col).getPropertyValue('--bs-gutter-y'))
        })

        // if the set has different gutters apply the default
        guttersX = new Set(guttersX)
        const gutterX = guttersX.size > 1 ? this.settings.gutterX : guttersX.values().next().value

        guttersY = new Set(guttersY)
        const gutterY = guttersY.size > 1 ? this.settings.gutterY : guttersY.values().next().value

        if (gutterX !== rowGutterX) {
            row.style.setProperty('--bs-gutter-x', gutterX)
        }

        if (gutterY !== rowGutterY) {
            row.style.setProperty('--bs-gutter-y', gutterY)
        }

        const mixed = guttersX.size > 1 || guttersY.size > 1

        if (mixed) {
            row.dataset.paragraphsGutterMixed = 'true'

            row.children.forEach(col => {
                col.style.setProperty('--bs-gutter-x', gutterX)
                col.style.setProperty('--bs-gutter-y', gutterY)
            })
        }
    }
}

const init = (Drupal, drupalSettings, once) => {
    // eslint-disable-next-line no-unused-vars
    const bootstrapGridHelper = new BootstrapGridHelper(Drupal, drupalSettings, once)
}

export default init

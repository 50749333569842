const search = {
    init: Drupal => {
        function searchIsVisible() {
            const headerSearch = document.querySelector('.header-search')
            return headerSearch.classList.contains('is-active')
        }

        Drupal.mosaiq.searchIsVisible = searchIsVisible

        function handleFocus() {
            const headerSearchToggler = document.querySelector('.header-search-toggler')
            const headerSearch = document.querySelector('.header-search')

            if (searchIsVisible()) {
                headerSearch.querySelector('input[type="search"]').focus()
            } else {
                headerSearchToggler.focus()
            }
        }

        function toggleSearchVisibility(visibility) {
            const headerSearchToggles = document.querySelectorAll('.header-search-toggler')
            const headerSearch = document.querySelector('.header-search')

            for (const headerSearchToggler of headerSearchToggles) {
                headerSearchToggler.setAttribute('aria-expanded', visibility === true)
            }

            headerSearch.addEventListener('transitionend', handleFocus, {
                once: true
            })

            if (visibility === true) {
                headerSearch.classList.add('is-active')
                Drupal.mosaiq.mqBackdrop.show('search')
            } else {
                headerSearch.classList.remove('is-active')
                Drupal.mosaiq.mqBackdrop.hide('search')
            }
        }

        Drupal.mosaiq.toggleSearchVisibility = toggleSearchVisibility

        document.addEventListener('click', e => {
            if (e.target.matches('.header-search-toggler, .header-search-toggler *')) {
                toggleSearchVisibility(!searchIsVisible())
                // close navigation
                Drupal.mosaiq.closeAllSubNav()
            } else if (
                searchIsVisible()
                && !e.target.matches('.header-search, .header-search *')
            ) {
                toggleSearchVisibility(false)
            }
        })

        document.addEventListener('keyup', e => {
            if (e.key === 'Escape' && searchIsVisible()) {
                toggleSearchVisibility(false)
            }
        })
    }
}

export default search
